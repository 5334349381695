import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Github, LinkedinSquare, Twitter, ReactLogo, Redux, Nodejs, Html5, Css3, Javascript, Angular } from 'styled-icons/boxicons-logos';
import gatsbyLogo from '../images/gatsby.svg';
import typescriptLogo from '../images/typescript.svg';
import ContactLink from '../components/contact-link';
import { SvgLogoImg } from '../components/contact-link/style';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`What I do`}</h2>
    <p>{`I help our feature squads meet customers' and bankers' high expectations
for quality and familiarity by implementing the core interface elements,
icons, text styles, and uniform terminology that make up the Ocean Blue
Design System and component library.`}</p>
    <p>{`I work across feature squads and with specialist UI designers to identify
emerging trends, and turn them into reusable patterns; develop and
maintain the Design Systems documentation; and advocate for UI standards
and accessibility with designers, engineers, and stakeholders across many
areas of ANZ.`}</p>
    <hr></hr>
    <h2>{`Capabilities`}</h2>
    <ContactLink href='https://reactjs.org/' color='#00d8ff' dark mdxType="ContactLink">
  <ReactLogo size='48' mdxType="ReactLogo" /> React
    </ContactLink>
    <ContactLink href='https://redux.js.org/' color='#764abc' mdxType="ContactLink">
  <Redux size='48' mdxType="Redux" /> Redux
    </ContactLink>
    <ContactLink href='https://styled-components.com/' color='rgb(219, 112, 147)' mdxType="ContactLink">
  💅 Styled Components
    </ContactLink>
    <ContactLink href='https://www.gatsbyjs.org/' color='#663399' mdxType="ContactLink">
  <SvgLogoImg src={gatsbyLogo} alt='Gatsby JS Logo' mdxType="SvgLogoImg" /> Gatsby
    </ContactLink>
    <ContactLink href='https://nodejs.org/en/' color='#43853d' mdxType="ContactLink">
  <Nodejs size='48' mdxType="Nodejs" /> Node
    </ContactLink>
    <ContactLink href='https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5' color='#e34f26' mdxType="ContactLink">
  <Html5 size='48' mdxType="Html5" /> HTML 5
    </ContactLink>
    <ContactLink href='https://developer.mozilla.org/en-US/docs/Web/CSS/CSS3' color='rgb(32, 98, 175)' mdxType="ContactLink">
  <Css3 size='48' mdxType="Css3" /> CSS 3
    </ContactLink>
    <ContactLink href='https://developer.mozilla.org/en-US/docs/Web/JavaScript' color='#f7df1e' dark mdxType="ContactLink">
  <Javascript size='48' mdxType="Javascript" /> JavaScript
    </ContactLink>
    <ContactLink href='https://www.typescriptlang.org/' color='#007ACD' mdxType="ContactLink">
  <SvgLogoImg src={typescriptLogo} alt='TypeScript Logo' mdxType="SvgLogoImg" /> TypeScript
    </ContactLink>
    <ContactLink href='https://angularjs.org/' color='#b52e31' mdxType="ContactLink">
  <Angular size='48' mdxType="Angular" /> Angular
    </ContactLink>
    <hr></hr>
    <h2>{`Contact details`}</h2>
    <ContactLink href='https://github.com/loliver' color='#333' mdxType="ContactLink">
  <Github size='48' mdxType="Github" /> GitHub
    </ContactLink>
    <ContactLink href='https://www.linkedin.com/in/oliver-gassman-79193515/' color='#0077b5' mdxType="ContactLink">
  <LinkedinSquare size='48' mdxType="LinkedinSquare" /> LinkedIn
    </ContactLink>
    <ContactLink href='https://twitter.com/lololololiver' color='#1da1f2' dark mdxType="ContactLink">
  <Twitter size='48' mdxType="Twitter" /> Twitter
    </ContactLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      